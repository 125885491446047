<template>
  <div class="HeaderActions">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.HeaderActions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}
</style>
